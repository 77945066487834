import { isMobile } from '../utils/index'
export default {
  address: '台北市北投區稻香路161巷',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.8151853186164!2d121.48595451500837!3d25.141938383921985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442afb6a8fd040f%3A0xb0fa08ec57a3f2!2z5qi55bGx5LiYIOaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1632453057861!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/j7hpAToJQJRn9SJK7',
  phone: '02-28979888',
  fbLink: 'https://www.facebook.com/citynaturehouse/',
  fbMessage: 'https://m.me/citynaturehouse/',
  caseName: '樹山丘',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '興益發建設企業股份有限公司'],
    ['外觀設計', '李天鐸建築事務所'],
    ['建築規劃', '梁正芳建築事務所'],
    ['公設門廳', '墨工空間'],
    ['景觀園藝', '李天鐸建築事務所'],
    ['建築代銷', '自建自售'],
    ['建照號碼', '107建字第0182號'],
    ['基地面積', '近3000坪'],
    ['坪數規劃', '26-38坪 2-3房'],
    ['建築規劃', '地上五層、地下三層'],
  ],

  gtmCode: ['KC6DKK5'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '預約賞屋' : '預約賞屋',
    subTitle: ''
  }
}
